import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { Button, ApplicationStepsProgress, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const ApplicationStepsProgressDocs = () => {

    const [currentStep, setCurrentStep] = useState(1);
    const stepsNames = ['Step 1', 'Step 2', 'Step 3', '', 'Step 5', 'Step 6', 'Step 7'];
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Application Steps Progress</h1>

            <ApplicationStepsProgress
                stepsNames={stepsNames}
                className="mb-3"
                steps={7}
                currentStep={currentStep}
                description={<><strong>Current step:</strong> Waiting Co-Applicant</>}
            />
            <Button onClick={() => setCurrentStep(currentStep + 1)}>Add step</Button>

            <Highlight className="React mb-4">
                {
                    `
import React, { useState } from 'react';
import { Button, ApplicationStepsProgress } from '@jkhy/vsg-design-system';

const Example = (props) => {

    const [currentStep, setCurrentStep] = useState(1);
    
    // If stepsNames length < steps or there is empty string step name tooltip won't be rendered.
    const stepsNames = ['Step 1', 'Step 2', 'Step 3', '', 'Step 5', 'Step 6', 'Step 7'];

    return (
    <div>
        <ApplicationStepsProgress 
               steps={7} 
               currentStep={currentStep} 
               description={<><strong>Current step:</strong> Waiting Co-Applicant</>} 
        />
        
        <Button onClick={() => setCurrentStep(currentStep + 1)}>Add step</Button>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>steps</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Set total steps.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.3" />stepsNames</td>
                        <td>array of strings</td>
                        <td className="text-center">-</td>
                        <td>Set Tooltip to steps. If stepsNames length &lt; steps or there is empty string step name tooltip won't be rendered.</td>
                    </tr>
                    <tr>
                        <td>currentStep</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Determines the current step.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.3" />description</td>
                        <td>string || JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Set description.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ApplicationStepsProgressDocs;