import React, { useState } from 'react';

import {
  Button, Section, FormGroup, Select, Radio, Input, ActionsWrapper, IconsSolid,
  ReactAux, Textarea
} from '@jkhy/vsg-design-system';

const PageRenDeclarations = () => {
  const [isOfficer, setIsOfficer] = useState(false);
  const [isEmployee, setisEmployee] = useState(false);

  return (
    <ReactAux>
      <Section
        title="Declarations"
        footerText="Click ‘Continue’ to proceed."
      >
        <p className="mb-3 text-muted">Please answer the following questions.</p>

        <FormGroup label="If you have a banking relationship with a current banker, please select their name from the drop down." className="mb-2" htmlFor="banking">
          <Select
            name="banking"
            id="banking"
            placeholder="Select..."
            options={[
              { value: 'John officer', label: 'John officer' },
              { value: 'Jane officer', label: 'Jane officer' },
              { value: 'Sam Doe', label: 'Sam Doe' }]}
          />
        </FormGroup>

        <FormGroup className="mb-2" checkboxOrRadio label="Are you an Executive Officer of the bank?">
          <Radio htmlFor="isOfficerYes" id="isOfficerYes" className="d-inline-block" name="officer" value="yes" onChange={() => setIsOfficer(true)}>Yes</Radio>
          <Radio htmlFor="isOfficerNo" id="isOfficerNo" className="d-inline-block" name="officer" value="no" onChange={() => setIsOfficer(false)}>No</Radio>
        </FormGroup>
        {
          isOfficer && (
            <FormGroup htmlFor="officerExplain" label="If yes, please explain" className="mb-2">
              <Textarea rows={4} id="officerExplain" name="officer_explain" />
            </FormGroup>
          )
        }

        <FormGroup className="mb-2" isRequired checkboxOrRadio label="Do you consent to receiving communication electronically via e-mail?">
          <Radio htmlFor="emailCominicationYes" id="emailCominicationYes" className="d-inline-block" name="email_comunication" value="yes">Yes</Radio>
          <Radio htmlFor="emailCominicationNo" id="emailCominicationNo" className="d-inline-block" name="email_comunication" value="no">No</Radio>
        </FormGroup>

        <FormGroup className="mb-2" isRequired checkboxOrRadio label="How did you hear about this online loan application?">
          <Radio htmlFor="applicationInfoRef" id="applicationInfoRef" name="application" value="reffereal" onChange={() => setisEmployee(false)}>Customer Reffereal</Radio>
          <Radio htmlFor="applicationInfoEmail" id="applicationInfoEmail" name="application" value="email" onChange={() => setisEmployee(false)}>E-mail</Radio>
          <Radio htmlFor="applicationInfoEmployee" id="applicationInfoEmployee" name="application" value="employee" onChange={() => setisEmployee(true)}>Employee-Banker</Radio>
        </FormGroup>
        {
          isEmployee && (
            <FormGroup isRequired label="Employee-Banker’s Name" htmlFor="bankerName" className="mb-2">
              <Input type="text" name="banker_name" id="bankerName" />
            </FormGroup>
          )
        }

      </Section>

      <ActionsWrapper className="mt-2">
        <Button iconRight={IconsSolid.faChevronRight} link={{ path: `/renewals/pages/5` }}>Next step</Button>
      </ActionsWrapper>
    </ReactAux>
  );
}

export default PageRenDeclarations;