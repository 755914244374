import React from 'react';
import Highlight from 'react-highlight';
import { Input, FontAwesomeIcon, IconsSolid, FormGroup, MaskedInput, Select, Radio, Checkbox } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';
import DemoWrapper from './DemoWrapper';

const FormGroupDocs = () => {
    const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Form Group</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-2'>Wrap your form controls in this component and set <strong>label</strong> and <strong>htmlFor</strong>. Set <strong>id</strong> to form controls to assign the label to it. You can also set <strong>tooltip</strong>.</p>
            <p className='mb-3'>
                <strong style={{ color: 'var(--color-danger)' }}>IMPORTANT:</strong> Add prop <strong>dsSelectWrapper</strong> to the component which uses and return <strong>Select</strong> component. Form Group component add some aditional props to
             Select Component, so you need to spread the props from your wrapper component to Select component.
             </p>

            <div className='mb-2'>
                <FormGroup htmlFor="fgroupInput" label='Input' isRequired={true} className="mb-2">
                    <Input id="fgroupInput" />
                </FormGroup>
                <FormGroup htmlFor="maskFormGroup" label='Phone Masked Input' className="mb-2">
                    <MaskedInput
                        id="maskFormGroup"
                        type='phone'
                        mask={usPhoneCountryCodeMask}
                        icon={IconsSolid.faPhoneAlt}
                    />
                </FormGroup>
                <FormGroup htmlFor="selectFG" label='Select' isRequired={true} className="mb-2" hoverHelp={'Tooltip <a href="https://google.com" target="_blank">Link<a/>'}>
                    <Select
                        id="selectFG"
                        options={[
                            { value: 'chocolate', label: 'Chocolate' },
                            { value: 'strawberry', label: 'Strawberry' },
                            { value: 'vanilla', label: 'Vanilla' }]}
                    />
                </FormGroup>
                <FormGroup label="Candy Options" htmlFor="fgCandySelect" className="mb-2" >
                    <DemoWrapper
                        dsSelectWrapper
                        id="fgCandySelect"
                        placeholder="Select..."
                        options={[
                            { value: 'chocolate', label: 'Chocolate' },
                            { value: 'strawberry', label: 'Strawberry' },
                            { value: 'vanilla', label: 'Vanilla' }]}
                    />
                </FormGroup>
                <FormGroup label='Radio' checkboxOrRadio isBold={true} className="mb-2">
                    <Radio className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Radio>
                    <Radio className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Radio>
                </FormGroup>
                <FormGroup label='Checkbox' checkboxOrRadio isRequired={true} className="mb-2" isValid={false} validationMessage="Invalid field">
                    <Checkbox className="d-inline-block" htmlFor="vehicle4-inline" id="vehicle4-inline" name="vehicle4-inline" value="Bike">I have a bike</Checkbox>
                    <Checkbox className="d-inline-block" htmlFor="vehicle5-inline" id="vehicle5-inline" name="vehicle5-inline" value="Car">I have a car</Checkbox>
                    <Checkbox className="d-inline-block" htmlFor="vehicle6-inline" id="vehicle6-inline" name="vehicle6-inline" value="Boat">I have a boat</Checkbox>
                </FormGroup>
                <FormGroup htmlFor="fullName" label='Full Name' className="mb-2" hoverHelp="Full name" isBold={true}>
                    <Input id="fullName" />
                </FormGroup>
                <FormGroup label="Radio" checkboxOrRadio isRequired={true} className="mb-2" isValid={false} validationMessage="Choose field">
                    <Radio className="d-inline-block" htmlFor="agreeYes2" id="agreeYes2" name="agree2" value="yes">Yes</Radio>
                    <Radio className="d-inline-block" htmlFor="agreeNo2" id="agreeNo2" name="agree2" value="no">No</Radio>
                </FormGroup>
                <FormGroup htmlFor="fginvalidInput" className="mb-2" label="Invalid Input" isRequired={true} isValid={false} validationMessage="This field is required">
                    <Input id="fginvalidInput" />
                </FormGroup>
                <FormGroup htmlFor="maskFGInvalid" label='Phone Masked Input' className="mb-2" isValid={false} validationMessage="Invalid field">
                    <MaskedInput
                        id="maskFGInvalid"
                        type='phone'
                        mask={usPhoneCountryCodeMask}
                        icon={IconsSolid.faPhoneAlt}
                    />
                </FormGroup>
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { 
    FormGroup, Select, Input, 
    MaskedInput, Radio, Checkbox 
} from '@jkhy/vsg-design-system';
import DemoWrapper from './DemoWrapper';

const Example = (props) => {
    return (
    <div>
            <FormGroup htmlFor="fgroupInput" label='Input' isRequired={true} className="mb-2">
                <Input id="fgroupInput"/>
            </FormGroup>
            <FormGroup htmlFor="maskFormGroup" label='Phone Masked Input' className="mb-2">
                <MaskedInput
                    id="maskFormGroup"
                    type='phone'
                    mask={usPhoneCountryCodeMask}
                    icon={IconsSolid.faPhoneAlt}
                />
            </FormGroup>
            <FormGroup htmlFor="selectFG" label='Select' isRequired={true} className="mb-2">
                <Select
                    id="selectFG"
                    options={[
                        { value: 'chocolate', label: 'Chocolate' },
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'vanilla', label: 'Vanilla' }]}
                />
            </FormGroup>
            <FormGroup label="Candy Options" htmlFor="fgCandySelect" className="mb-2" >
                <DemoWrapper
                    dsSelectWrapper
                    id="fgCandySelect"
                    placeholder="Select..."
                    options={[
                        { value: 'chocolate', label: 'Chocolate' },
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'vanilla', label: 'Vanilla' }]}
                />
             </FormGroup>
            <FormGroup label='Radio' checkboxOrRadio isBold={true} className="mb-2">
                <Radio className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Radio>
                <Radio className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Radio>
            </FormGroup>
            <FormGroup label='Checkbox' checkboxOrRadio isRequired={true} className="mb-2" isValid={false} validationMessage="Invalid field">
                <Checkbox className="d-inline-block" htmlFor="vehicle4-inline" id="vehicle4-inline" name="vehicle4-inline" value="Bike">I have a bike</Checkbox>
                <Checkbox className="d-inline-block" htmlFor="vehicle5-inline" id="vehicle5-inline" name="vehicle5-inline" value="Car">I have a car</Checkbox>
                <Checkbox className="d-inline-block" htmlFor="vehicle6-inline" id="vehicle6-inline" name="vehicle6-inline" value="Boat">I have a boat</Checkbox>
            </FormGroup>

            // with tooltip
            <FormGroup htmlFor="fullName" label='Full Name' className="mb-2" hoverHelp="Full name" isBold={true}>
                <Input id="fullName" />
            </FormGroup>
            <FormGroup label="Radio" checkboxOrRadio isRequired={true} className="mb-2" isValid={false} validationMessage="Choose field">
                <Radio className="d-inline-block" htmlFor="agreeYes2" id="agreeYes2" name="agree2" value="yes">Yes</Radio>
                <Radio className="d-inline-block" htmlFor="agreeNo2" id="agreeNo2" name="agree2" value="no">No</Radio>
            </FormGroup>
            <FormGroup htmlFor="fginvalidInput" className="mb-2" label="Invalid Input" isRequired={true} isValid={false} validationMessage="This field is required">
                <Input id="fginvalidInput" />
            </FormGroup>
            <FormGroup htmlFor="maskFGInvalid" label='Phone Masked Input' className="mb-2" isValid={false} validationMessage="Invalid field">
                <MaskedInput
                    id="maskFGInvalid"
                    type='phone'
                    mask={usPhoneCountryCodeMask}
                    icon={IconsSolid.faPhoneAlt}
                />
            </FormGroup>
    </div>
    );
}

export default Example; 

// Base Example for Select Wrapper
import React from 'react';
import { Select } from '@jkhy/vsg-design-system';

const DemoWrapper = (props: any) => {

    return (
            <Select
                {...props}
            />
    );
}

export default DemoWrapper;
`
                }
            </Highlight>

            <table className="doc-table mb-3" id="formGroupProps">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>label</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Sets label title to the group.<br />
                            <strong>Notice:</strong> Always set value to this prop if you have inputs on same row, to have them with equal height.
                    </td>
                    </tr>
                    <tr>
                        <td>isHidden</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Determines whether the content of the FormGroup is hidden.</td>
                    </tr>
                    <tr>
                        <td>isRequired</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Hides the '(optional)' text.</td>
                    </tr>
                    <tr>
                        <td>isBold</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Makes the label bold.</td>
                    </tr>
                    <tr>
                        <td>hoverHelp</td>
                        <td>string | JSX.Element</td>
                        <td className='text-center'>-</td>
                        <td>Set tooltip icon next to the label. <br />
                            <strong>Required:</strong> label prop to be set with value.
                    </td>
                    </tr>
                    <tr>
                        <td>isValid</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>Show validation message text.</td>
                    </tr>
                    <tr>
                        <td>validationMessage</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set validation message text.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />htmlFor</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>
                            Set <strong>Label</strong> htmlFor text due to WCAG 2.0 and focus form control in form group on label click.<br />
                            <strong>Note:</strong> Don't add htmlFor when wrap Radio and Checkbox in FormGroup.
                    </td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />checkboxOrRadio</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Set form group styles when checkbox and radio buttons wrapped in formgroup.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default FormGroupDocs;