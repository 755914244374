import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
    Section, ApplicationInfoCard, IconsSolid, Button, Collapse, FileUploadCard,
    ApplicationStepsProgress, ListBox, ListBoxContainer
} from '@jkhy/vsg-design-system';

const CurrentApplicationsPage = () => {

    let fileUploadCardRef1: any = null;

    const stepsNames = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

    const listBoxes = [
        {
            title: {
                text: 'Primary Borrower:',
                isBold: true,
            },
            description: 'Sally Jones'
        },
        {
            title: {
                text: 'Date Started:',
                isBold: true
            },
            description: '01/02/2021'
        },
        {
            title: {
                text: 'Amount Requested:',
                isBold: true

            },
            description: '$35,000'
        },
        {
            iconSettings: {
                icon: IconsSolid.faInfoCircle,
                className: 'text-primary'
            },
            description: 'Expires in 3 days.'
        }
    ];

    const listBoxesSe =  [
        {
            iconSettings: {
                icon: IconsSolid.faComments,
                className: 'text-primary'
            },
            title: {
                text: 'Need Help?'
            },
            description: <Link to="/" title="Chat with us"> Chat with us</Link>
        },
        {
            iconSettings: {
                icon: IconsSolid.faIdCard,
                className: 'text-primary'
            },
            title: {
                text: 'Banker’s Name:',
                isBold: true
            },
            description: 'John Banker'
        },
        {
            iconSettings: {
                icon: IconsSolid.faPhone,
                className: 'text-primary'
            },
            title: {
                text: 'Mobile:',
                isBold: true
            },
            description: <a href="tel:555-555-5555" title="555-555-5555">555-555-5555</a>
        },
        {
            iconSettings: {
                icon: IconsSolid.faEnvelope,
                className: 'text-primary'
            },
            title: {
                text: 'E-mail:',
                isBold: true
            },
            description: <a href="mailto:john@banker.com" title="john@banker.com">john@banker.com </a>
        }
    ];

    const [collapses, setCollapses] = useState([false, false]);

    const handleCollapseClick = (index: number) => {
        const collapsesCopy = [...collapses];
        collapsesCopy[index] = !collapses[index];

        setCollapses(collapsesCopy);
    }

    // Set custom Error messages
    const getErrorMessage = (type: any, ref: any) => {
        switch (type) {
            case 'invalid name':
                return { title:'Invalid file name.'}
            case 'exceeded maximum size':
                return {title:`File exceeds maximum file size (${ref.props.maxFileSizeMB || 50}MB.)`};
            case 'invalid type':
                return {title:'Invalid file type.'}
            case 'pdf file is password protected':
                return {title:'File is password protected', desc: 'Uploaded file appears to have a password protection. Please upload a file that is not password protected.'}
            default:
                return {title:'Failed to upload'};
        }
    }

    const handleOnChange = (files: any, ref: any) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.error) {
                const message = getErrorMessage(file.error, ref);

                // If file contains error call this function
                ref.onFileError(file, message.title, message.desc);
            } else {
                // Simulated uploading with random time for upload.
                const time = +((Math.floor(Math.random() * 10) + 1) + '000');
                let progress = 0;

                const interval = setInterval(() => {
                    progress += 1;
                    ref.updateProgress(file, progress);
                }, time / 100)

                // If there error while uploading call this function
                // ref.onFileError(file, 'custom error message');

                setTimeout(() => {
                    clearInterval(interval);

                    const isFileValid = Math.round(Math.random());
                    if (isFileValid) {
                        // If success call this function to set file success
                        ref.onFileSuccess(file);
                    } else {
                       // Simulate file is uploaded, but server retuns some error, after check it
                       ref.onFileError(file, 'Error to read file', 'File is locked with password');
                    }

                }, time)
            }
        }
    }

    const handleOnSubmit = (ref: any, demoError?: boolean) => {
        // Get the files
        console.log(ref.state.files, ' onSubmit - files');

        // Simulated submit time
        setTimeout(() => {
            if (demoError) {
                // If error call this function with error message
                ref.onSubmitError('Internal Server Error please try again.');
            } else {
                // If success call this function
                ref.onSubmitSuccess('File was submitted.', true);
            }
        }, 2000)
    }

    return (
        <Section title="Current Applications">
            <p className="mb-3">The applications listed below are all still in progress and will remain here until your loan is approved and your funds have been issued. These applications are either waiting to be submitted, waiting for information to be provided, or your banker is working on providing loan documents to be signed. Please review as needed.</p>
            <ApplicationInfoCard
                className="mb-3"
                title="Application #A1234"
                buttonsRender={
                    <>
                        <Button className="w-50 action-spacer" btnType="secondary">withdraw application</Button>
                        <Button className="w-50">open application</Button>
                    </>
                }
            >

                <ApplicationStepsProgress
                    className="mb-2"
                    steps={7}
                    currentStep={1}
                    description={<><strong>Current step:</strong> Waiting Co-Applicant</>}
                    stepsNames={stepsNames}
                />
                 <ListBoxContainer>
                    <ListBox
                        boxItems={listBoxes} />
                    <ListBox
                        boxItems={listBoxesSe} />
                </ListBoxContainer>

                <Collapse
                    title="Required Documents"
                    expanded={collapses[0]}
                    onClick={() => handleCollapseClick(0)}
                    badgeContent={4}
                >
                    <FileUploadCard
                        className="mt-1"
                        title="Personal Tax Return"
                        details={[
                            { title: 'Required for:', text: 'Sally Jones' },
                            { title: 'Statement Date:', text: '15/10/2019' },
                            { title: 'Description:', text: '2019 Personal Taxt Return' },
                        ]}
                        isMultiple
                        maxFileSizeMB={2}
                        onRef={(ref: any) => (fileUploadCardRef1 = ref)}
                        onChange={(files: any) => {
                            handleOnChange(files, fileUploadCardRef1);
                        }}
                        onSubmit={(files: any) => handleOnSubmit(fileUploadCardRef1)}
                        type='compact'
                    />
                </Collapse>
            </ApplicationInfoCard>

            <ApplicationInfoCard
                title="Application #A1234"
                buttonsRender={<>
                    <Button className="w-50 action-spacer" btnType="secondary">withdraw application</Button>
                    <Button className="w-50">open application</Button>
                </>}
            >
                <ApplicationStepsProgress
                    className="mb-2"
                    steps={7}
                    currentStep={1}
                    description={<><strong>Current step:</strong> Waiting Co-Applicant</>}
                    stepsNames={stepsNames}
                />
                <ListBoxContainer>
                    <ListBox
                        boxItems={listBoxes} />
                    <ListBox
                        boxItems={listBoxesSe} />
                </ListBoxContainer>
                
                <Collapse
                    title="Required Documents"
                    expanded={collapses[1]}
                    onClick={() => handleCollapseClick(1)}
                    badgeContent={5}
                >
                    <p>Content...</p>
                </Collapse>
            </ApplicationInfoCard>
        </Section>
    );
}

export default CurrentApplicationsPage;