import React from 'react';
import Highlight from 'react-highlight';
import { Input, FontAwesomeIcon, IconsSolid, FormGroup, Select, MultipleFormGroup, Row, Col, MaskedInput } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const InputDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Multiple Form Group</h1>

            <div className='mb-2'>
                <MultipleFormGroup
                    hoverHelp={"Group hover help"}
                    multiFormGroupProps={{
                        label: 'Choose expiry date',
                    }}
                    groupColProps={{
                        lg: '12',
                    }}
                    groupElements={[
                        {
                            element: <FormGroup
                                label="Year"
                                htmlFor="mYear"
                                isMultipleGroup
                            >
                                <Input id="mYear" type="number" placeholder="YYYY" />
                            </FormGroup>,
                            elementColSize: 6,
                        },
                        {
                            element: <FormGroup
                                label="Month"
                                htmlFor="mMonth"
                                isMultipleGroup
                            >
                                <Select
                                    id="mMonth"
                                    options={[
                                        { value: 'Jan', label: 'Jan' },
                                        { value: 'Feb', label: 'Feb' },
                                        { value: 'Mar', label: 'Mar' },
                                        { value: 'Apr', label: 'Apr' },
                                        { value: 'May', label: 'May' },
                                        { value: 'June', label: 'June' },
                                        { value: 'July', label: 'July' },
                                        { value: 'Aug', label: 'Aug' },
                                        { value: 'Sept', label: 'Sept' },
                                        { value: 'Oct', label: 'Oct' },
                                        { value: 'Nov', label: 'Nov' },
                                        { value: 'Dec', label: 'Dec' },
                                    ]}
                                />
                            </FormGroup>,
                            elementColSize: 6,
                        },
                    ]}
                    className="mb-2"
                />

                <MultipleFormGroup
                    multiFormGroupProps={{
                        isRequired: true,
                        label: 'Gross Employment Income',
                    }}
                    groupColProps={{
                        lg: '12',
                    }}
                    groupElements={[
                        {
                            element: <FormGroup label='Period' isMultipleGroup htmlFor="Period" isRequired isBold={true} >
                                <Select
                                    id="Period"
                                    options={[
                                        { value: 'month', label: 'Monthly' },
                                        { value: 'anual', label: 'Anually' },
                                    ]}
                                />
                            </FormGroup>,
                            elementColSize: 6,
                        },
                        {
                            element: <FormGroup label="Amount" isMultipleGroup isRequired htmlFor="maskNumber">
                                <MaskedInput
                                    id="maskNumber"
                                    type='number'
                                    icon={IconsSolid.faDollarSign}
                                    onChange={(e: any) => console.log("displayed value:" + e.target.value, "formatted value:" + e.target.formattedValue)}
                                />
                            </FormGroup>,
                            elementColSize: 6,
                        },
                    ]}
                    className="mb-2"
                />

                <MultipleFormGroup
                    multiFormGroupProps={{
                        isRequired: true,
                        label: 'Multiple From group example invalid and long labels'
                    }}
                    groupColProps={{
                        lg: '9'
                    }}
                    groupElements={[
                        {
                            element: <FormGroup
                                label="Label"
                                htmlFor="mLabel"
                                isValid={false}
                                isMultipleGroup
                            >
                                <Input id="mLabel" type="number" placeholder="YYYY" />
                            </FormGroup>,
                            elementColSize: 4,
                            validationMessage: "Invalid field",
                            isValid: false
                        },
                        {
                            element:
                                <FormGroup
                                    label="Select Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                    htmlFor="mselLabel"
                                    isValid={false}
                                    isMultipleGroup
                                >
                                    <Select
                                        id="mselLabel"
                                        options={[
                                            { value: 'one', label: 'Option One' },
                                            { value: 'two', label: 'Option Two' },
                                            { value: 'other', label: 'Other' },
                                        ]}
                                    />
                                </FormGroup>,
                            elementColSize: 8,
                            isValid: false,
                            validationMessage: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the "
                        }
                    ]}
                />
            </div>

            <div className='mb-2'>
                <p className="mb-2"><strong>Example MultipleFormGroup wrapped in Col</strong></p>
                <Row>
                    <Col lg='4'>
                        <MultipleFormGroup
                            className="mb-2"
                            multiFormGroupProps={{
                                label: 'Choose expiry date',
                            }}
                            groupColProps={{
                                xs: ""
                            }}
                            groupElements={[
                                {
                                    element: <FormGroup
                                        label="Year"
                                        htmlFor="mcYear"
                                        isValid={true}
                                        validationMessage="test"
                                        isMultipleGroup
                                        isRequired
                                    >
                                        <Input id="mcYear" type="number" placeholder="YYYY" />
                                    </FormGroup>,
                                    elementColSize: 6,
                                    validationMessage: "Invalid field",
                                    isValid: true
                                },
                                {
                                    element: <FormGroup
                                        isRequired
                                        label="Month"
                                        htmlFor="mcMonth"
                                        isValid={true}
                                        isMultipleGroup
                                    >
                                        <Select
                                            id="mcMonth"
                                            options={[
                                                { value: 'Jan', label: 'Jan' },
                                                { value: 'Feb', label: 'Feb' },
                                                { value: 'Mar', label: 'Mar' },
                                                { value: 'Apr', label: 'Apr' },
                                                { value: 'May', label: 'May' },
                                                { value: 'June', label: 'June' },
                                                { value: 'July', label: 'July' },
                                                { value: 'Aug', label: 'Aug' },
                                                { value: 'Sept', label: 'Sept' },
                                                { value: 'Oct', label: 'Oct' },
                                                { value: 'Nov', label: 'Nov' },
                                                { value: 'Dec', label: 'Dec' },
                                            ]}
                                        />
                                    </FormGroup>,
                                    elementColSize: 6,
                                    isValid: true,
                                    validationMessage: "Invalid field"
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </div>


            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { MultipleFormGroup, Select, Row } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
        <MultipleFormGroup
            hoverHelp={"Group hover help"}
            multiFormGroupProps={{
                label: 'Choose expiry date',
            }}
            groupColProps={{
                lg: '12',
            }}
            groupElements={[
                {
                    element: <FormGroup
                        label="Year"
                        htmlFor="mYear"
                        isMultipleGroup
                    >
                        <Input id="mYear" type="number" placeholder="YYYY" />
                    </FormGroup>,
                    elementColSize: 6,
                },
                {
                    element: <FormGroup
                        label="Month"
                        htmlFor="mMonth"
                        isMultipleGroup
                    >
                        <Select
                            id="mMonth"
                            options={[
                                { value: 'Jan', label: 'Jan' },
                                { value: 'Feb', label: 'Feb' },
                                { value: 'Mar', label: 'Mar' },
                                { value: 'Apr', label: 'Apr' },
                                { value: 'May', label: 'May' },
                                { value: 'June', label: 'June' },
                                { value: 'July', label: 'July' },
                                { value: 'Aug', label: 'Aug' },
                                { value: 'Sept', label: 'Sept' },
                                { value: 'Oct', label: 'Oct' },
                                { value: 'Nov', label: 'Nov' },
                                { value: 'Dec', label: 'Dec' },
                            ]}
                        />
                    </FormGroup>,
                    elementColSize: 6,
                },
            ]}
            className="mb-2"
        />
        
        <MultipleFormGroup
            multiFormGroupProps={{
                isRequired: true,
                label: 'Gross Employment Income',
            }}
            groupColProps={{
                lg: '12',
            }}
            groupElements={[
                {
                    element: <FormGroup label='Period' isMultipleGroup htmlFor="Period" isRequired isBold={true}>
                        <Select
                            id="Period"
                            options={[
                                { value: 'month', label: 'Monthly' },
                                { value: 'anual', label: 'Anually' },
                            ]}
                        />
                    </FormGroup>,
                    elementColSize: 6,
                },
                {
                    element: <FormGroup label="Amount" isMultipleGroup isRequired htmlFor="maskNumber">
                        <MaskedInput
                            id="maskNumber"
                            type='number'
                            icon={IconsSolid.faDollarSign}
                            onChange={(e: any) => console.log("displayed value:" + e.target.value, "formatted value:" + e.target.formattedValue)}
                        />
                    </FormGroup>,
                    elementColSize: 6,
                },
            ]}
            className="mb-2"
        />

        <MultipleFormGroup
            multiFormGroupProps={{
                isRequired: true,
                label: 'Multiple From group example invalid and long labels'
            }}
            groupColProps={{
                lg: '9'
            }}
            groupElements={[
                {
                    element: <FormGroup
                        label="Label"
                        htmlFor="mLabel"
                        isValid={false}
                        isMultipleGroup
                    >
                        <Input id="mLabel" type="number" placeholder="YYYY" />
                    </FormGroup>,
                    elementColSize: 4,
                    validationMessage: "Invalid field",
                    isValid: false
                },
                {
                    element:
                        <FormGroup
                            label="Select Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                            htmlFor="mselLabel"
                            isValid={false}
                            isMultipleGroup
                        >
                            <Select
                                id="mselLabel"
                                options={[
                                    { value: 'one', label: 'Option One' },
                                    { value: 'two', label: 'Option Two' },
                                    { value: 'other', label: 'Other' },
                                ]}
                            />
                        </FormGroup>,
                    elementColSize: 8,
                    isValid: false,
                    validationMessage: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the "
                }
            ]}
        />

       // Example MultipleFormGroup wrapped in Col
        <Row>
            <Col lg='4'>
                <MultipleFormGroup
                    className="mb-2"
                    multiFormGroupProps={{
                        label: 'Choose expiry date',
                    }}
                    groupColProps={{
                        xs: ""
                    }}
                    groupElements={[
                        {
                            element: <FormGroup
                                label="Year"
                                htmlFor="mcYear"
                                isValid={true}
                                validationMessage="test"
                                isMultipleGroup
                                isRequired
                            >
                                <Input id="mcYear" type="number" placeholder="YYYY" />
                            </FormGroup>,
                            elementColSize: 6,
                            validationMessage: "Invalid field",
                            isValid: true
                        },
                        {
                            element: <FormGroup
                                isRequired
                                label="Month"
                                htmlFor="mcMonth"
                                isValid={true}
                                isMultipleGroup
                            >
                                <Select
                                    id="mcMonth"
                                    options={[
                                        { value: 'Jan', label: 'Jan' },
                                        { value: 'Feb', label: 'Feb' },
                                        { value: 'Mar', label: 'Mar' },
                                        { value: 'Apr', label: 'Apr' },
                                        { value: 'May', label: 'May' },
                                        { value: 'June', label: 'June' },
                                        { value: 'July', label: 'July' },
                                        { value: 'Aug', label: 'Aug' },
                                        { value: 'Sept', label: 'Sept' },
                                        { value: 'Oct', label: 'Oct' },
                                        { value: 'Nov', label: 'Nov' },
                                        { value: 'Dec', label: 'Dec' },
                                    ]}
                                />
                            </FormGroup>,
                            elementColSize: 6,
                            isValid: true,
                            validationMessage: "Invalid field"
                        },
                    ]}
                />
            </Col>
    </Row>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="obsolete" />formGroupProps</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />multiFormGroupProps</td>
                        <td>
                            Object with props: <br />
                            "label": string, <br />
                            "isHidden": boolean, <br />
                            "isRequired": boolean,
                        </td>
                        <td className='text-center'>-</td>
                        <td>Sets label to group. Hide or show the group. Hide or show "optional" text after label.</td>
                    </tr>
                    <tr>
                        <td>groupColProps</td>
                        <td>
                            Object with props:<br />
                            ReactStrap props <a target="_blank" href='https://reactstrap.github.io/components/layout/' rel="noopener noreferrer"><strong>here</strong></a><br />
                            "isHidden": "boolean" - hide column,<br />
                            "className": "string"
                        </td>
                        <td>xs="12"</td>
                        <td>Sets grid col classes to the wrapping element of the form controls.</td>
                    </tr>
                    <tr>
                        <td>groupElements</td>
                        <td>
                            Array of objects with props:<br />
                            "element": JSX.Element,<br />
                            "elementColSize": number from 1 to 12<br />
                            "validationMessage": "string",<br />
                            "isValid": "boolean"
                        </td>
                        <td className="text-center">elementColSize="6"</td>
                        <td>Set the form elements.</td>
                    </tr>
                    <tr>
                        <td> <PropVersionTag type="new" version='2024.09.513'/>hoverHelp</td>
                        <td>string | JSX.Element</td>
                        <td className='text-center'>-</td>
                        <td>Set tooltip icon next to the label. <br />
                            <strong>Required:</strong> label prop to be set with value.
                        </td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set class name to the Form group wrapper.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default InputDocs;