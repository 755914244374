import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import 'highlight.js/styles/dracula.css';
import '../docs-styles.scss';

import Sidebar from './Sidebar/Sidebar';
import DocsContainer from './section-layout/DocContainer/DocContainer';
import {
    Alert,
    Button,
    Datepicker,
    PricingCard,
    Introduction,
    SpacingUtils,
    ActionsWrapper,
    Toast as ToastPage,
    Loader,
    Testing,
    Modal,
    Header,
    Footer,
    Container,
    Tooltip as TooltipPage,
    NavSteps,
    ReactAux,
    AddFormSectionButton,
    ConfirmDeleteButton,
    ProgressBar,
    Typography,
    DisplayUtils,
    TextUtils,
    WidthUtils,
    NavMenu,
    InfoCard,
    FileUploadCardDocs,
    Banner,
    FileUploadHistoryCard,
    TimeOutMessage,
    FontAwesomeIcon,
    LinkWithIcon,
    HorizonalInfoCard,
    Collapse,
    MainContainer,
    DemoPages,
    MigrationGuide,
    Fab,
    Avatar,
    Section,
    ScrollableContainer,
    LayoutQuickDecision,
    LayoutClientPortal,
    DataPreviewText,
    Input,
    IntegerInput,
    MaskedInput,
    Zipcode,
    Checkbox,
    Radio,
    Textarea,
    Select,
    AddressAutocomplete,
    FormGroup,
    MultipleFormGroup,
    FormSection,
    Grid,
    RadioVsSelect,
    TextEditorContentWrapper,
    Buttons,
    InterdependentFormControls,
    ApplicationInfoCard,
    ApplicationStepsProgress,
    FuncUtils,
    LoanRequestBox,
    GitFlow, 
    ListBox,
    ListBoxContainer
} from './sections';

import { ToastContainer } from '@jkhy/vsg-design-system';


class Docs extends Component {

    private static readonly DOCS_HTML_CLASS = 'docs-body';

    componentDidMount() {
        const body = document.body;
        body.classList.add(Docs.DOCS_HTML_CLASS);
    }

    componentWillUnmount() {
        const body = document.body;
        body.classList.remove(Docs.DOCS_HTML_CLASS);
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    render() {
        return <>
            {/* // Init toast */}
            <ToastContainer />
            <Sidebar />
            <DocsContainer>
                <Switch>
                    <Route path="/docs/alert" component={Alert} />
                    <Route path="/docs/avatar" component={Avatar} />
                    <Route path="/docs/grid" component={Grid} />
                    <Route path="/docs/git-flow" component={GitFlow} />
                    <Route path="/docs/address-autocomplete" component={AddressAutocomplete} />
                    <Route path="/docs/button" component={Button} />
                    <Route path="/docs/checkbox" component={Checkbox} />
                    <Route path="/docs/container" component={Container} />
                    <Route path="/docs/datepicker" component={Datepicker} />
                    <Route path="/docs/footer" component={Footer} />
                    <Route path="/docs/formgroup" component={FormGroup} />
                    <Route path="/docs/pricingCard" component={PricingCard} />
                    <Route path="/docs/form-section" component={FormSection} />
                    <Route path="/docs/header" component={Header} />
                    <Route path="/docs/introduction" component={Introduction} />
                    <Route path="/docs/actionsWrapper" component={ActionsWrapper} />
                    <Route path="/docs/link-with-icon" component={LinkWithIcon} />
                    <Route path="/docs/loader" component={Loader} />
                    <Route path="/docs/horizontal-info-card" component={HorizonalInfoCard} />
                    <Route path="/docs/infoCard" component={InfoCard} />
                    <Route path="/docs/input" component={Input} />
                    <Route path="/docs/integer-input" component={IntegerInput} />
                    <Route path="/docs/modal" component={Modal} />
                    <Route path="/docs/toast" component={ToastPage} />
                    <Route path="/docs/testing" component={Testing} />
                    <Route path="/docs/textarea" component={Textarea} />
                    <Route path="/docs/tooltip" component={TooltipPage} />
                    <Route path="/docs/navMenu" component={NavMenu} />
                    <Route path="/docs/navSteps" component={NavSteps} />
                    <Route path="/docs/radio" component={Radio} />
                    <Route path="/docs/radio-vs-select" component={RadioVsSelect} />
                    <Route path="/docs/buttons" component={Buttons} />
                    <Route path="/docs/reactAux" component={ReactAux} />
                    <Route path="/docs/fab" component={Fab} />
                    <Route path="/docs/fontAwesomeIcon" component={FontAwesomeIcon} />
                    <Route path="/docs/fileUploadHistoryCard" component={FileUploadHistoryCard} />
                    <Route path="/docs/addFormSectionButton" component={AddFormSectionButton} />
                    <Route path="/docs/confirmDeleteButton" component={ConfirmDeleteButton} />
                    <Route path="/docs/progressBar" component={ProgressBar} />
                    <Route path="/docs/Typography" component={Typography} />
                    <Route path="/docs/utilities/spacing" component={SpacingUtils} />
                    <Route path="/docs/utilities/display" component={DisplayUtils} />
                    <Route path="/docs/utilities/text" component={TextUtils} />
                    <Route path="/docs/utilities/width" component={WidthUtils} />
                    <Route path="/docs/utilities/helper-functions" component={FuncUtils} />
                    <Route path="/docs/fileUploadCard" component={FileUploadCardDocs} />
                    <Route path="/docs/banner" component={Banner} />
                    <Route path="/docs/time-out-message" component={TimeOutMessage} />
                    <Route path="/docs/collapse" component={Collapse} />
                    <Route path="/docs/mainContainer" component={MainContainer} />
                    <Route path="/docs/maskedinput" component={MaskedInput} />
                    <Route path="/docs/multiple-formgroup" component={MultipleFormGroup} />
                    <Route path="/docs/demo-pages" component={DemoPages} />
                    <Route path="/docs/migration-guide" component={MigrationGuide} />
                    <Route path="/docs/section" component={Section} />
                    <Route path="/docs/select" component={Select} />
                    <Route path="/docs/scrollable-container" component={ScrollableContainer} />
                    <Route path="/docs/layout-quick-decision" component={LayoutQuickDecision} />
                    <Route path="/docs/layout-client-portal" component={LayoutClientPortal} />
                    <Route path="/docs/data-preview-text" component={DataPreviewText} />
                    <Route path="/docs/zipcode" component={Zipcode} />
                    <Route path="/docs/text-editor-content-wrapper" component={TextEditorContentWrapper} />
                    <Route path="/docs/interdependent-form-controls" component={InterdependentFormControls} />
                    <Route path="/docs/application-info-card" component={ApplicationInfoCard} />
                    <Route path="/docs/application-steps-progress" component={ApplicationStepsProgress} />
                    <Route path="/docs/loan-request-box" component={LoanRequestBox} />
                    <Route path="/docs/listbox" component={ListBox} />
                    <Route path="/docs/listbox-container" component={ListBoxContainer} />
                    <Redirect from="/docs/*" to="/docs/introduction" />
                </Switch>
            </DocsContainer>
        </>
    }
}

export default Docs;