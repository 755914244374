import React from 'react';
import { Link } from 'react-router-dom';

import logo from './logo.png';
import './sidebar.scss';

import LinkItem from './LinkItem/LinkItem';

const Sidebar = () => {

    return <div className="doc-sidebar">
        <div className="space"></div>
        <div className="menu-header">
            <Link to="/">
                <img style={{ height: '45px' }} src={logo} alt="VSG" />
            </Link>
            <h1>Design System</h1>
            <span>v. 4.4</span>
        </div>
        <div className="menu-links">
            <LinkItem title="Getting started" links={[
                { title: 'Introduction', path: '/docs/introduction' },
                { title: 'Migration to v.4.1', path: '/docs/migration-guide' },
                { title: 'Git Flow', path: '/docs/git-flow' },
            ]} />
            <LinkItem title="Typography" links={[
                { title: 'Basic', path: '/docs/typography' }
            ]} />
            <LinkItem title="Guidelines" links={[
                { title: 'Buttons', path: '/docs/buttons' },
                { title: 'Interdependent Form Controls', path: '/docs/interdependent-form-controls' },
                { title: 'Radio vs Select', path: '/docs/radio-vs-select' }
            ]} />
            <LinkItem title="Components" links={[
                { title: 'AddFormSectionButton', path: '/docs/addFormSectionButton' },
                { title: 'Alert', path: '/docs/alert' },
                { title: 'Avatar', path: '/docs/avatar' },
                { title: 'ApplicationInfoCard', path: '/docs/application-info-card' },
                { title: 'ApplicationStepsProgress', path: '/docs/application-steps-progress' },
                { title: 'Banner', path: '/docs/banner' },
                { title: 'Collapse', path: '/docs/collapse' },
                { title: 'ConfirmDeleteButton', path: '/docs/confirmDeleteButton' },
                { title: 'DataPreviewText', path: '/docs/data-preview-text' },
                { title: 'Fab', path: '/docs/fab' },
                { title: 'FontAwesomeIcon', path: '/docs/fontAwesomeIcon' },
                { title: 'HorizontalInfoCard', path: '/docs/horizontal-info-card' },
                { title: 'InfoCard', path: '/docs/infoCard' },
                { title: 'LinkWithIcon', path: '/docs/link-with-icon' },
                { title: 'ListBox', path: '/docs/listbox' },
                { title: 'ListBoxContainer', path: '/docs/listbox-container' },
                { title: 'Loader', path: '/docs/loader' },
                { title: 'LoanRequestBox', path: '/docs/loan-request-box' },
                { title: 'Modal', path: '/docs/modal' },
                { title: 'PricingCard', path: '/docs/pricingCard' },
                { title: 'ProgressBar', path: '/docs/progressBar' },
                { title: 'ReactAux', path: '/docs/reactAux' },
                { title: 'TimeOutMessage', path: '/docs/time-out-message' },
                { title: 'Toast', path: '/docs/toast' },
                { title: 'Tooltip', path: '/docs/tooltip' },
            ]} />
            <LinkItem title="Forms" links={[
                { title: 'FormGroup', path: '/docs/formgroup' },
                { title: 'MultipleFormGroup', path: '/docs/multiple-formgroup' },
                { title: 'AddressAutocomplete', path: '/docs/address-autocomplete' },
                { title: 'Button', path: '/docs/button' },
                { title: 'Checkbox', path: '/docs/checkbox' },
                { title: 'Datepicker', path: '/docs/datepicker' },
                { title: 'Input', path: '/docs/input' },
                { title: 'IntegerInput', path: '/docs/integer-input' },
                { title: 'FileUploadCard', path: '/docs/fileUploadCard' },
                { title: 'FileUploadHistoryCard', path: '/docs/fileUploadHistoryCard' },
                { title: 'FormSection', path: '/docs/form-section' },
                { title: 'MaskedInput', path: '/docs/maskedinput' },
                { title: 'Radio', path: '/docs/radio' },
                { title: 'Select', path: '/docs/select' },
                { title: 'Textarea', path: '/docs/textarea' },
                { title: 'Zipcode', path: '/docs/zipcode' },

            ]} />
            <LinkItem title="Layout Components" links={[
                { title: 'Layout QuickDecision', path: '/docs/layout-quick-decision' },
                { title: 'Layout ClientPortal', path: '/docs/layout-client-portal' },
                { title: 'ActionsWrapper', path: '/docs/actionsWrapper' },
                { title: 'Container', path: '/docs/container' },
                { title: 'Footer', path: '/docs/footer' },
                { title: 'Grid', path: '/docs/grid' },
                { title: 'Header', path: '/docs/header' },
                { title: 'NavMenu', path: '/docs/navMenu' },
                { title: 'MainContainer', path: '/docs/mainContainer' },
                { title: 'NavSteps', path: '/docs/navSteps' },
                { title: 'ScrollableContainer', path: '/docs/scrollable-container' },
                { title: 'Section', path: '/docs/section' },
                { title: 'TextEditorContentWrapper', path: '/docs/text-editor-content-wrapper' },
            ]} />
            <LinkItem title="Utilities" links={[
                { title: 'Display', path: '/docs/utilities/display' },
                { title: 'Helper Functions', path: '/docs/utilities/helper-functions' },
                { title: 'Text', path: '/docs/utilities/text' },
                { title: 'Spacing', path: '/docs/utilities/spacing' },
                { title: 'Width', path: '/docs/utilities/width' },
                { title: 'Testing', path: '/docs/testing' }
            ]} />
            <LinkItem title="Demo Pages" links={[
                { title: 'Demo Pages', path: '/docs/demo-pages' },
            ]} />
        </div>
    </div>
}

export default Sidebar;