import React from 'react';
import Highlight from 'react-highlight';
import PropVersionTag from '../PropVersionTag/PropVersionTag';
import { Toast, ToastContainer, Button, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const notifySuccess = () => Toast.success('Success message here!');
const notifyDanger = () => Toast.danger('Danger message here!');
const notifyWarning = () => Toast.warning('Warning message here!');
const notifyInfo = () => Toast.info('Info message here!');
const notifySuccessTime = () => Toast.success('Success message here! Without auto close.', {
    autoClose: false
});
const notifyDangerTime = () => Toast.danger('Danger message here! With long time for close.', {
    autoClose: 20000
});

const ToastDocs = () => {

    return <div>
        <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Toast</h1>

        <h2 className='mb-2'>Description</h2>
        <p className='mb-4'>
            Toasts are often used to show messages on the screen.
            <br /> QD Design System Toast components use "react-toastify" library.
            Check demo <a href="https://fkhadra.github.io/react-toastify/" target="_blank" rel="noopener noreferrer">here</a> and documentation <a href="https://github.com/fkhadra/react-toastify#readme" target="_blank" rel="noopener noreferrer">here</a>.
        </p>

        <p className='mb-4'>
            <b>Note:</b> Remember to render the <b>ToastContainer</b> once in application tree.
        </p>

        <ToastContainer />

        <h3 className='mb-3'>Toast types</h3>
        <p className='mb-2'>
            There are 4 types of Toast components: "success", "danger", "warning" and "info".
            <br /> Click on the button to show the toast:
        </p>

        <div className='mb-2'>
            <Button className="mr-1 mb-1" onClick={notifySuccess}>Success toast</Button>
            <Button className="mr-1 mb-1" onClick={notifyDanger}>Danger toast</Button>
            <Button className="mr-1 mb-1" onClick={notifyWarning}>Warning toast</Button>
            <Button className="mr-1 mb-1" onClick={notifyInfo}>Info toast</Button>
            <Button className="mr-1 mb-1" onClick={notifySuccessTime}>Success toast with custom props</Button>
            <Button className="mr-1 mb-1" onClick={notifyDangerTime}>Danger toast with custom props</Button>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Toast, ToastContainer, Button } from '@jkhy/vsg-design-system';

// Initialize ToastContainer. Call it once. At the root of app is the best place.
<ToastContainer dataUI={getDataUI()} />

// Methods example
const notifySuccess = () => Toast.success('Success message here!');
const notifyDanger = () => Toast.danger('Danger message here!');
const notifyWarning = () => Toast.warning('Warning message here!');
const notifyInfo = () => Toast.info('Info message here!');
const notifySuccessTime = () => Toast.success('Success message here! Without auto close.', {
    autoClose: false
});
const notifyDangerTime = () => Toast.danger('Danger message here! With long time for close.', {
    autoClose: 20000
});
const Example = (props) => {
    return (
    <div>
        <Button className="mr-1 mb-1" onClick={notifySuccess}>Success toast</Button>
        <Button className="mr-1 mb-1" onClick={notifyDanger}>Danger toast</Button>
        <Button className="mr-1 mb-1" onClick={notifyWarning}>Warning toast</Button>
        <Button className="mr-1 mb-1" onClick={notifyInfo}>Info toast</Button>
        <Button className="mr-1 mb-1" onClick={notifySuccessTime}>Success toast with custom props</Button>
        <Button className="mr-1 mb-1" onClick={notifyDangerTime}>Danger toast with custom props</Button>
    </div>
    );
}
export default Example; 
    `}
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan={4}>Methods</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Props</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>success</td>
                    <td>content, options</td>
                    <td>Display a success toast.</td>
                </tr>
                <tr>
                    <td>danger</td>
                    <td>content, options</td>
                    <td>Display a danger toast.</td>
                </tr>
                <tr>
                    <td>warning</td>
                    <td>content, options</td>
                    <td>Display a warning toast.</td>
                </tr>
                <tr>
                    <td><PropVersionTag type="new" /> info</td>
                    <td>content, options</td>
                    <td>Display a info toast.</td>
                </tr>
            </tbody>
        </table>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan={4}>Methods Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Parameter</strong>
                    </td>
                    <td>
                        <strong>Type</strong>
                    </td>
                    <td>
                        <strong>Required</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>content</td>
                    <td>string or React Element</td>
                    <td>Yes</td>
                    <td>Element that will be displayed.</td>
                </tr>
                <tr>
                    <td>options</td>
                    <td>object</td>
                    <td>No</td>
                    <td><a href="https://github.com/fkhadra/react-toastify#api" target="_blank" rel="noopener noreferrer">Options list.</a></td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default ToastDocs;